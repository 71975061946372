<template>
  <!-- 滑块验证，获取验证码组件 -->
  <div id="inputCode">
    <!-- <div class="codeBtn btn" @click="getSlide" v-if="codeType == 'slide'">点击按钮进行验证</div>
    <div class="codeConfirm" v-if="codeType == 'confirm'">
      <img src="../../assets/account/binding.png" />
      <span>验证成功</span>
    </div> -->
    <!-- 获取验证码 -->
    <div class="code">
      <input
        placeholder="请输入验证码"
        @input="codeInput"
        v-model="codeValue"
      />
      <div class="btn" @click="getSlide">
        {{ btnMessage }}
      </div>
    </div>
    <!-- end of 获取验证码 -->
    <p v-if="codeType == 'code'">该手机还可以获得2次验证码,请尽快验证</p>
    <!-- 图片验证码 -->
    <div class="modal-shadaw" v-if="is_verify">
      <div class="modal-box">
        <div class="modal-title">
          <div class="left">图片验证码</div>
          <div class="right" @click="is_verify = !1">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-box">
            <img :src="verifyImg" class="verify-img" @click="getCodeImg" />
            <div class="input-box">
              <el-input
                type="text"
                class="form-input"
                ref="input"
                @blur="codeBtn"
                v-model="verify_code"
                placeholder="请输入验证码"
              />
              <div class="input-error">{{ code_error }}</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <el-button size="small" class="btn" @click="is_verify = !1"
            >取消</el-button
          >
          <!-- confirmBtn -->
          <el-button size="small" type="primary" class="btn" @click="getCode"
            >确定</el-button
          >
        </div>
      </div>
    </div>
    <!-- end of 图片验证码 -->
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: String,
    },
    type: {
      type: String,
      default: "mobile",
    },
    check: {
      //检查手机号是否注册
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      codeType: "slide",
      btnMessage: "点击获取",
      time: 120,
      is_time: !0,
      timer: "",
      is_verify: !1,
      verifyImg: "",
      verify_id: "",
      verify_code: "",
      code_error: "",
      codeValue: "",
    };
  },
  methods: {
    confirmBtn() {
      let value = this.verify_code;
      if (!value) {
        this.$refs["input"].focus();
        return (this.code_error = "请输入验证码");
      }
      this.is_verify = !1;
      this.codeType = "confirm";
      let timer = setTimeout(() => {
        this.codeType = "code";
        clearTimeout(timer);
      }, 1000);
    },
    codeInput() {
      this.$emit("code", this.codeValue);
    },
    getSlide() {
      //注册为手机
      if (this.type == "mobile") {
        if (!this.mobile) {
          this.$emit("error", { msg: "请输入手机号码" });
        } else if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
          this.$emit("error", { msg: "请输入正确的手机号码" });
        } else {
          if (this.check) {
            this.$axios
              .post("/api/users/mobile/valid", { mobile: this.mobile })
              .then((res) => {
                if (res.code == 200) {
                  this.is_verify = !0;
                  this.getCodeImg();
                  this.verify_code = "";
                  this.$emit("getVerifyCode", !0);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg || "系统错误,联系管理员");
              });
          } else {
            this.is_verify = !0;
            this.getCodeImg();
            this.verify_code = "";
            this.$emit("getVerifyCode", !0);
          }
        }
      }
      //注册为邮箱
      let regMail =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (this.type == "email") {
        if (!this.mobile) {
          this.$emit("error", { msg: "请输入邮箱" });
        } else if (!regMail.test(this.mobile)) {
          this.$emit("error", { msg: "请输入正确的邮箱地址" });
        } else {
          this.is_verify = !0;
          this.getCodeImg();
          this.verify_code = "";
          this.$emit("getVerifyCode", !0);
        }
      }
    },
    codeBtn() {
      this.code_error = "";
    },
    getCode() {
      if (this.is_time) {
        let data = {
          receiver: this.mobile,
          verify_value: this.verify_code,
          captcha_id: this.verify_id,
          method: this.type,
        };
        this.$axios
          .post("/api/captcha/send", data)
          .then((res) => {
            if (res.code == 200) {
              this.is_time = !1;
              this.is_verify = !1;
              let data = res.data;
              this.timer = setInterval(() => {
                this.time--;
                this.btnMessage = `${this.time}秒后重新获取`;
                if (this.time == 0) {
                  this.btnMessage = "重新获取";
                  clearInterval(this.timer);
                  this.time = 120;
                  this.is_time = !0;
                }
              }, 1000);
            }
          })
          .catch((err) => {
            this.code_error = err.msg;
            this.$refs["input"].focus();
            // this.codeType = 'slide'
            // this.$emit('error',err.msg)
          });
      }
    },
    getCodeImg() {
      this.$axios
        .post("/api/captcha/generate", { captcha_type: "math" })
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            this.verifyImg = data.b64s;
            this.verify_id = data.captcha_id;
          }
        })
        .catch((err) => {
          err.msg && this.$Message.error(err.msg || "系统错误,联系管理员");
        });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
#inputCode {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .modal-shadaw {
    flex: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.1);
    .modal-box {
      background-color: #ffffff;
      border-radius: 4px;
      width: 480px;
      height: 250px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .modal-title {
        display: flex;
        justify-content: space-between;
        padding-left: 12px;
        height: 48px;
        line-height: 48px;
        border-bottom: 1px solid #efefef;
        .left {
          font-size: 18px;
          color: #333333;
        }
        .right {
          font-size: 20px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #f95f5f;
            color: #ffffff;
          }
        }
      }
      .modal-body {
        padding-top: 32px;
        padding-bottom: 32px;
        .form-box {
          display: flex;
          align-items: center;
          padding-left: 32px;
          .input-box {
            height: 32px;
            position: relative;
            .input-error {
              color: #f74848;
              position: absolute;
              left: 0;
              bottom: -100%;
            }
          }
          .form-input {
            width: 180px;
            margin-left: 20px;
          }
        }
        .verify-img {
          width: 200px;
          height: auto;
          cursor: pointer;
        }
      }
      .modal-footer {
        display: flex;
        justify-content: center;
        .btn {
          width: 80px;
          height: 32px;
          &:first-child {
            margin-right: 12px;
          }
        }
      }
    }
  }
  > .btn {
    width: 440px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    font-size: 18px;
    border: 1px solid;
    border-radius: 3px;
    cursor: pointer;
  }
  > .codeBtn {
    color: #101010;
    border-color: #dce0e7;
  }
  > .codeConfirm {
    width: 440px;
    height: 52px;
    line-height: 52px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 18px;
    border: 1px solid #dce0e7;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
  > .code {
    width: 440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // animation: left 0.3s;
    // @keyframes left {
    //   from {left: -440px;}
    //   to {left: 0}
    // }
    > input {
      height: 48px;
      width: 290px;
      border-radius: 3px;
      border: 1px solid #dce0e7;
      color: #333;
      font-size: 16px;
      padding-left: 10px;
    }
    > input::-webkit-input-placeholder {
      font-size: 16px;
      color: #ccc;
    }
    > .btn {
      height: 48px;
      width: 126px;
      border-radius: 3px;
      line-height: 48px;
      text-align: center;
      color: #f74848;
      border: 1px solid #f74848;
      font-size: 16px;
      cursor: pointer;
    }
  }
  > p {
    width: 440px;
    line-height: 20px;
    color: #969696;
    margin-top: 5px;
  }
}
</style>
