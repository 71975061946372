<template>
  <!-- 注册页面-平台 -->
  <div id="resgister" :class="[isApp ? 'app' : '']">
    <!-- app端 头部-->
    <header v-if="isApp" class="app">
      <div class="app-header-container" id="appHeader">
        <div class="header-info">
          <div class="plat-info">
            <div class="left">
              <img
                :src="platInfo.logo_url"
                @click="$utils.goIndexPage"
                class="logo"
              />
              <span>{{ platInfo.name }}</span>
            </div>
            <img
              src="@/assets/img/login/user.png"
              @click="$router.push('/loginIndex')"
              class="icon-user"
            />
          </div>
          <div class="welcome-text-info">
            <div class="welcome" v-if="loginInfo.disseminate_type == 'text'">
              {{ loginInfo.text }}
            </div>
            <!-- <div class="left" v-if="loginInfo.disseminate_type == 'img'">
              <img :src="loginInfo.img.attachment_path" />
            </div> -->
          </div>
        </div>
        <el-carousel
          indicator-position="outside"
          class="carousel-box"
          height="100%"
          :interval="3000"
        >
          <el-carousel-item v-for="(item, index) in loginImgArr" :key="index">
            <img
              :src="item.attachment_path"
              style="height: 100%; width: 100%; object-fit: cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </header>
    <!-- PC端 头部-->
    <div class="header" v-else>
      <div class="content">
        <div class="plat-info">
          <img
            :src="platInfo.logo_url"
            class="logo"
            @click="$utils.goIndexPage"
          />
          <span>{{ platInfo.name }}</span>
        </div>
        <!-- 宣传语 -->
        <div class="welcome-box">
          <div class="welcome" v-if="loginInfo.disseminate_type == 'text'">
            {{ loginInfo.text }}
          </div>
          <div class="left" v-if="loginInfo.disseminate_type == 'img'">
            <img class="welcome-logo" :src="loginInfo.img.attachment_path" />
          </div>
        </div>
        <div class="login">
          <span>已有账号？</span>
          <label @click="$router.push('/login')">请登录></label>
        </div>
      </div>
    </div>

    <!-- APP端页面 -->
    <div v-if="isApp" class="app-body">
      <div class="content">
        <!-- 输入验证码 -->
        <div class="inputs app-el-form" v-if="step == 1">
          <div class="app-zuce">欢迎注册为{{ platInfo.name }}用户</div>
          <el-form>
            <el-form-item>
              <el-input
                placeholder="请输入手机号"
                v-model.number="mobile"
                class="input-with-select"
              >
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item prop="is_valid">
              <huakuaiApp
                ref="huakuai"
                width="420px"
                :mobile="mobile"
                @isOk="validBtn"
              ></huakuaiApp>
            </el-form-item>
            <el-form-item>
              <div class="code-box">
                <el-input
                  style="margin-right: 20px"
                  v-model="mobile_verity_code"
                  placeholder="请输入验证码"
                ></el-input>
                <div class="code-msg-btn">
                  <el-button
                    @click="getCodes"
                    :disabled="disable"
                    style="width: 100%; font-size: 1.4rem; line-height: 1.5"
                    >{{ showWorld }}</el-button
                  >
                </div>
              </div>
            </el-form-item>
            <div
              :span="24"
              v-if="agreeList && agreeList.length > 0"
              class="agreement-box"
            >
              <el-checkbox v-model="agreement" class="radio-box"> </el-checkbox>
              <div class="agree-box">
                我已阅读并同意<span
                  v-for="(item, index) in agreeList"
                  :key="index"
                >
                  <span class="agree" @click="agreenBtn(item)"
                    >《{{ item.title }}》</span
                  >
                  <template v-if="index < agreeList.length - 1">和</template>
                </span>
              </div>
            </div>
            <div class="btns">
              <el-button
                @click="checkMobile"
                style="
                  width: 100%;
                  font-size: 1.4em;
                  line-height: 1.5;
                  border-radius: 0.2em;
                "
                type="danger"
                >下一步</el-button
              >
            </div>
          </el-form>
        </div>
        <!-- 输入密码 -->
        <div class="inputs set-box" v-if="step == 2">
          <div class="app-zuce">欢迎注册为{{ platInfo.name }}用户</div>
          <el-form
            class="app-el-form"
            :rules="pwdRules"
            :model="pwdForm"
            ref="pwdForm"
          >
            <el-form-item prop="value1">
              <el-input
                type="password"
                show-password
                v-model="pwdForm.value1"
                placeholder="请输入登录密码"
              ></el-input>
              <div class="password-info">
                密码为8-16位字母/数字/符号，不能是纯数字
              </div>
            </el-form-item>
            <el-form-item prop="value2">
              <el-input
                type="password"
                show-password
                v-model="pwdForm.value2"
                placeholder="请再次输入密码"
              ></el-input>
            </el-form-item>
            <div>
              <el-button
                style="
                  width: 100%;
                  font-size: 1.4rem;
                  line-height: 1.5;
                  border-radius: 0.2rem;
                "
                type="danger"
                @click="registerBtn"
                >完成</el-button
              >
            </div>
          </el-form>
          <div class="tiaoguo">
            <span @click="registerBtn(2)">跳过密码设置</span>
          </div>
        </div>
      </div>
    </div>
    <!-- PC端页面 -->
    <div class="body" id="rentBg" v-else>
      <el-carousel
        indicator-position="outside"
        class="carousel-box"
        height="100%"
        :interval="3000"
      >
        <el-carousel-item v-for="(item, index) in loginImgArr" :key="index">
          <img
            :src="item.attachment_path"
            style="height: 100%; width: 100%; object-fit: cover"
          />
        </el-carousel-item>
      </el-carousel>
      <div class="content">
        <!-- 输入验证码 -->
        <div class="inputs" v-if="step == 1">
          <div class="box zuce">欢迎注册为{{ platInfo.name }}用户</div>
          <div class="box mobile-box">
            <el-input
              placeholder="请输入手机号"
              v-model.number="mobile"
              class="input-with-select"
            >
              <template slot="prepend">+86</template>
            </el-input>
          </div>
          <huakuai
            class="huakuai-box"
            :mobile="mobile"
            @isOk="validBtn"
            ref="huakuai"
          />
          <div class="box box2" style="border-color: transparent">
            <el-input
              style="margin-right: 20px"
              v-model="mobile_verity_code"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              @click="getCodes"
              :disabled="disable"
              style="width: 160px"
              >{{ showWorld }}</el-button
            >
          </div>
          <div
            class="resgister btn up"
            :class="{ disable: !agreement }"
            @click="checkMobile"
          >
            注册
          </div>
          <div class="bottom up" v-if="agreeList && agreeList.length > 0">
            <div class="check-box" style="margin-right: 4px">
              <el-checkbox v-model="agreement"></el-checkbox>
            </div>
            <p class="agree-box">
              我已阅读并同意<span
                v-for="(its, index) in agreeList"
                :key="index"
              >
                <i class="agree" @click="agreenBtn(its)">《{{ its.title }}》</i>
                <template v-if="index < agreeList.length - 1">和</template>
              </span>
            </p>
          </div>
        </div>
        <!-- 输入密码 -->
        <div class="inputs set-box" v-if="step == 2">
          <div class="box zuce">欢迎注册为{{ platInfo.name }}用户</div>
          <div class="input">
            <label>密码为8-16位字母/数字/符号，不能是纯数字</label>
            <input
              placeholder="请输入新的登录密码"
              :type="password"
              v-model="pwd"
              ref="pwd"
              @blur="pwdBlur('pwd')"
            />
            <img
              class="res"
              src="@/assets/img/popup/eyesClose.png"
              v-if="password == 'password'"
              @click="password = 'text'"
            />
            <img
              class="res"
              src="@/assets/img/popup/eyesOpen.png"
              v-if="password == 'text'"
              @click="password = 'password'"
            />
            <div class="input-error">{{ tips.pwd }}</div>
          </div>
          <div class="input">
            <input
              placeholder="请再次输入密码"
              :type="repassword"
              v-model="pwd2"
              ref="pwd2"
              @blur="pwdBlur('pwd2')"
            />
            <img
              src="@/assets/img/popup/eyesClose.png"
              v-if="repassword == 'password'"
              @click="repassword = 'text'"
            />
            <img
              src="@/assets/img/popup/eyesOpen.png"
              v-if="repassword == 'text'"
              @click="repassword = 'password'"
            />
            <div class="input-error">{{ tips.pwd2 }}</div>
          </div>
          <div class="resgister btn up" @click="registerBtn">完成</div>
          <div class="tiaoguo">
            <span @click="registerBtn(2)">跳过密码设置</span>
          </div>
        </div>
      </div>
    </div>

    <footers :navBar="navBar" v-if="!isApp" />
  </div>
</template>

<script>
import { wxShareConfige, shareWXQQ, isMobile } from "@/utils/wxConfig.js";
import {
  validatePhoneTwo,
  validatePassword,
  validateEMail,
} from "@/utils/validate.js";
import huakuaiApp from "@/views/login/loginHome/huakuaiApp.vue";
import inputCode from "./components/inputCode.vue";
import huakuai from "@/views/login/loginHome/huakuai.vue";
import footers from "@/components/footers.vue";
export default {
  components: {
    inputCode,
    huakuai,
    huakuaiApp,
    footers,
  },
  data() {
    // let pwdForm = this.pwdForm;
    let pwdRules = {
      value1: [{ trigger: "blur", validator: validatePassword }],
      value2: [
        {
          trigger: "blur",
          validator: (rule, val, call) => {
            console.log(this.pwdForm.value1, val);
            if (!val) {
              call(new Error("确认密码不能为空"));
            } else if (this.pwdForm.value1 != val) {
              call(new Error("两次密码不相同，请重新输入"));
            } else if (!/(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/.test(val)) {
              call(new Error("重置密码需包含数字和字母"));
            } else {
              call();
            }
          },
        },
      ],
    };

    return {
      agreement: false,
      step: 1,
      value: "",
      is_valid: false, //滑块验证
      mobile: "",
      mobile_verity_code: "",
      mobile_error: "",
      pwd: "",
      pwd2: "",
      tips: {
        pwd: "",
        pwd2: "",
      },
      pwdForm: {
        value1: "",
        value2: "",
      },
      pwdRules,
      repassword: "password",
      password: "password",
      showWorld: "点击获取验证码",
      disable: true,
      timers: null,
      n: 5,
      saveData: {},
      navBar: [],
      loginInfo: {},
      platInfo: {},
      loginImgArr: [],
      agreeList: [],
      isApp: false,
    };
  },
  created() {
    this.isApp = isMobile();
  },
  async mounted() {
    this.value = "+86";
    let query = this.$route.query;
    if (this.$route.query.k) {
      this.$axios
        .postJSON("/base/invite/active", { serial_number: this.$route.query.k })
        .then((res) => {
          if (res.code == 200) {
            // console.log('激活成功');
            let data = res.data;
            if (data && data.invite_method == 1) {
              this.mobile = data.invite_contact_mobile;
            }
          }
        })
        .catch((err) => {
          // err.msg && this.$message.error(err.msg);
        });
    }
    if (this.isApp) {
      // 1rem  相当于750尺寸设计图的 20px;
      // document.body.setAttribute('app','true');
      (function () {
        var html = document.querySelector("html");
        html.style.fontSize = (window.innerWidth / 750) * 20 + "px";
        window.onresize = function () {
          html.style.fontSize = (window.innerWidth / 750) * 20 + "px";
        };
      })();
      var fun = function (doc, win) {
        var docEl = doc.documentElement,
          resizeEvt =
            "orientationchange" in window ? "orientationchange" : "resize",
          recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            //这里是假设在750px宽度设计稿的情况下，1rem = 100px；
            //可以根据实际需要修改
            docEl.style.fontSize = 20 * (clientWidth / 750) + "px";
          };
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
      };
      fun(document, window);
    }
    this.getLoginSet();
    this.getAgreeList();
    this.$utils.getPlatformInfo().then((res) => {
      this.platInfo = res.data;
    });
    let datas = await wxShareConfige();
    if (this.$route.query.k) {
      this.$axios
        .postJSON("/base/invite/detail", { serial_number: this.$route.query.k })
        .then((res) => {
          if (res.code == 200) {
            if (datas === "1") {
              shareWXQQ(res.data.invite_set);
            }
          }
        });
    }
  },
  methods: {
    inputBlur() {
      this.mobile_error = "";
    },
    agreenBtn(row) {
      let route = this.$router.resolve({
        path: "/register/agreement",
        query: {
          id: row.id,
        },
      });
      window.open(route.href, "_blank");
    },
    getAgreeList() {
      this.$axios
        .get("/setting/agreement/reg_list")
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            if (data && data.length > 0) {
              this.agreeList = res.data;
            } else {
              this.agreement = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLoginSet() {
      this.$axios
        .get("/setting/public/reglogin")
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            if (!data) {
              return false;
            }
            let loginSet = {},
              loginImgArr = [];
            data.map((item) => {
              //背景图片
              if (item.key == "login_reg_pic_coll") {
                let dataArr = item.value.items;
                for (let i = 0, len = dataArr.length; i < len; i++) {
                  if (dataArr[i].status == 1) {
                    loginSet = dataArr[i];
                    loginImgArr.push(dataArr[i]);
                    // break;
                  }
                }
              }
              //底部导航
              if (item.key == "login_reg_bottom_nav") {
                if (
                  item.value &&
                  item.value.items &&
                  item.value.items instanceof Array
                ) {
                  this.navBar = item.value.items.filter(
                    (its) => its.status == 1
                  );
                }
              }
              //头部欢迎语
              if (item.key == "login_reg_base_setting") {
                this.loginInfo = item.value;
              }
            });
            if (loginImgArr.length > 0) {
              this.loginImgArr = loginImgArr;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          err.msg && this.$message.error(err.msg);
        });
    },
    validBtn(data) {
      //滑动验证
      this.saveData = data;
      console.log("data", data);
      if (data.isOk) {
        this.is_valid = true;
        this.disable = false;
      }
    },
    getCodes() {
      let val = this.saveData;
      let parma = {
        mobile: Number(this.mobile),
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      if (!this.is_valid) {
        this.$refs.huakuai.resets();
        this.$message.error("请向右滑动滑块验证");
        return false;
      }

      this.$axios.postJSON("/base/user/mobile/reg/send_code", parma).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.showWorld = "点击获取验证码";
                that.n = 10;
                that.disable = false;
                that.is_valid = false;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disable = true;
              }
            }, 1000);
          }
        },
        (err) => {
          this.$refs.huakuai.resets();
        }
      );
    },
    pwdBlur(field) {
      this.tips[field] = "";
    },
    getVerityCode(data) {
      this.mobile_verity_code = data;
      console.log("data", data);
    },
    regPassword(value) {
      let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
      let flag = !0,
        msg = "";
      if (!value) {
        (flag = !1), (msg = "不能为空");
      } else if (value.length < 6) {
        (flag = !1), (msg = "长度至少为6位");
      } else if (!regPwd.test(value)) {
        (flag = !1), (msg = "需包含数字或字母");
      }
      return { flag, msg };
    },
    registerBtn(val) {
      let query = this.$route.query;
      if (val == 2) {
        let data = {
          mobile: Number(this.mobile),
          mobile_verity_code: this.mobile_verity_code,
          is_read_agree: true,
        };
        if (query.k) {
          data.invite_serial_number = query.k;
        }
        this.$axios.postJSON("/base/user/mobile/reg", data).then((res) => {
          if (res.code == 200) {
            let data = res.data;
            this.$message({
              type: "success",
              message: "注册成功，请登录",
            });
            if (process.env.NODE_ENV === "production") {
              sessionStorage.removeItem("AccessToken");
            } else {
              localStorage.removeItem("AccessToken");
            }
            this.$router.push({
              path: "/login",
            });
          }
        });
      } else {
        if (this.isApp) {
          this.appPasswordRegister();
        } else {
          this.pcPasswordRegister();
        }
      }
    },
    appPasswordRegister() {
      //app端密码注册
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          let data = {
            mobile: Number(this.mobile),
            mobile_verity_code: this.mobile_verity_code,
            password: this.pwdForm.value1,
            password_confirm: this.pwdForm.value2,
            is_read_agree: true,
          };
          let query = this.$route.query;
          if (query.k) {
            data.invite_serial_number = query.k;
          }
          this.$axios.postJSON("/base/user/mobile/reg", data).then((res) => {
            if (res.code == 200) {
              let data = res.data;
              this.$message({
                type: "success",
                message: "注册成功，请登录",
              });
              localStorage.removeItem("AccessToken");
              this.$router.push({
                path: "/login",
              });
            }
          });
        }
      });
    },
    pcPasswordRegister() {
      //pc端密码注册
      let regPwdObj = this.regPassword(this.pwd),
        regPwd2Obj = this.regPassword(this.pwd2);
      if (!regPwdObj.flag) {
        this.$refs["pwd"].focus();
        this.tips.pwd = "登录密码" + regPwdObj.msg;
        return 1;
      }
      if (!regPwd2Obj.flag) {
        this.$refs["pwd2"].focus();
        this.tips.pwd2 = "确定密码" + regPwd2Obj.msg;
        return 1;
      }
      if (this.pwd != this.pwd2) {
        this.tips.pwd2 = "确定密码和登录密码不同";
        return 1;
      }
      let data = {
        mobile: Number(this.mobile),
        mobile_verity_code: this.mobile_verity_code,
        password: this.pwd,
        password_confirm: this.pwd2,
        is_read_agree: true,
      };
      let query = this.$route.query;
      if (query.k) {
        data.invite_serial_number = query.k;
      }
      this.$axios.postJSON("/base/user/mobile/reg", data).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.$message({
            type: "success",
            message: "注册成功，请登录",
          });
          if (process.env.NODE_ENV === "production") {
            sessionStorage.removeItem("AccessToken");
          } else {
            localStorage.removeItem("AccessToken");
          }
          this.$router.push({
            path: "/login",
          });
        }
      });
    },

    checkMobile() {
      //检查手机号是否注册
      if (!this.mobile) {
        return this.$message.error("请输入手机号");
      }
      if (!this.mobile_verity_code) {
        return this.$message({
          type: "error",
          message: "请输入验证码",
          duration: 100000,
        });
      }
      if (!this.agreement) {
        return this.$message.error("请同意用户服务协议");
      }
      this.step = 2;
    },
    getCodeError(data) {
      this.mobile_error = data.msg;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-body {
  margin: 0 1.5rem;
  background-color: #ffffff;
  padding: 2rem 1.5rem 2rem;
  border-radius: 1.5rem 1.5rem 0 0;
  position: relative;
  top: -2rem;
  z-index: 12;
  .app-zuce {
    margin-top: 1.2rem;
    color: #0088fe;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .code-box {
    display: flex;
    .code-msg-btn {
      width: 8em;
      margin-left: 0.5em;
    }
  }
  .password-info {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: #666666;
  }
  .tiaoguo {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
}
.agreement-box {
  display: flex;
  margin-bottom: 1.5rem;
  .agree-box {
    font-size: 1.4rem;
    color: #666666;
  }
}
</style>
<style lang="scss">
.app-header-container {
  font-size: calc(100vw / 750 * 20);

  box-sizing: border-box;
  height: 20.5rem;
  .header-info {
    position: relative;
    padding: 1.5em 1em;
    z-index: 5;
  }
  .carousel-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 20.5rem;
    width: 100vw;
    z-index: 3;
  }
  .plat-info {
    display: flex;
    font-size: 1.6em;
    color: #ffffff;
    height: 3.2em;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 3em;
        margin-right: 1em;
        height: auto;
      }
    }
    .icon-user {
      width: 1em;
      height: 1em;
    }
  }
  .welcome-text-info {
    margin-top: 1em;
    font-size: 1.8em;
    color: #ffffff;
    text-align: center;

    .left {
      img {
        height: 2em;
        width: auto;
      }
    }
  }
  .login-app {
    text-align: right;
    font-size: 1.3em;
    color: #666;
    .point {
      color: #f72626;
    }
  }
}
#resgister {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  &.app {
    width: 100vw;
  }
  // 导航头部
  > .header {
    width: 100%;
    height: 64px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 999;
    > .content {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      .plat-info {
        display: flex;
        align-items: center;
        font-size: 22px;
        .logo {
          height: 30px;
          margin-right: 24px;
          cursor: pointer;
        }
      }
      .welcome-box {
        font-size: 16px;
        .welcome {
          white-space: pre;
        }
      }
      .welcome-logo {
        height: 40px;
        width: auto;
        margin-left: 12px;
      }
      .login {
        position: absolute;
        right: 0;
        bottom: -30px;
        font-size: 16px;
        color: #333333;
        flex: 0;
        z-index: 10;
        > label {
          color: #f74848;
          cursor: pointer;
        }
      }
    }
  }
  // end of 头部导航
  .huakuai-box {
    margin-bottom: 20px;
  }
  > .body {
    width: 100%;
    flex: 1;
    background-color: #ffffff;
    position: relative;
    .carousel-box {
      height: 100%;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    > .content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      flex: 0;

      > .inputs {
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 560px;
          height: 480px;
          background-color: #ffffff;
          position: absolute;
          top: -40px;
          right: -56px;
          border: 1px solid transparent;
          box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
          z-index: 0;
        }
        &.set-box {
          .input-title {
            font-size: 18px;
            color: #666666;
            margin-bottom: 20px;
            font-weight: 650;
            font-family: "MiscoSoft YaHei";
            letter-spacing: 1px;
          }

          > .input {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 40px;
            > label {
              line-height: 25px;
              color: #999;
            }
            .input-error {
              flex: 0;
              position: absolute;
              bottom: -22px;
              left: 2px;
              color: #f74848;
            }
            > input {
              border: 1px solid #dce0e7;
              border-radius: 3px;
              width: 440px;
              height: 54px;
              padding-left: 20px;
              padding-right: 30px;
              box-sizing: border-box;
              font-size: 16px;
            }
            > input::-webkit-input-placeholder {
              color: #ccc;
              font-size: 16px;
            }
            > img {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 20px;
              top: 50%;
              margin-top: -10px;
              cursor: pointer;
            }
            > .res {
              margin-top: 5px;
            }
          }
        }
        > .box {
          display: flex;
          align-items: center;
          border-radius: 3px;
          width: 440px;
          margin-bottom: 20px;
          position: relative;
          .input-error {
            position: absolute;
            left: 2px;
            bottom: -22px;
            color: #f74848;
          }
          > .el-select {
            width: 80px;
            height: 53px;
            > .el-input {
              height: 100%;
              > .el-input__inner {
                border: none;
                height: 53px;
                font-size: 16px;
                line-height: 53px !important;
              }
            }
          }
          > span {
            width: 1px;
            height: 25px;
            background-color: #dce0e7;
          }
          > input {
            height: 40px;
            line-height: 40px;
            // height: 100%;
            border: none;
            padding-left: 10px;
            width: 300px;
            border-radius: 3px;
            color: #333;
            font-size: 16px;
          }
          > input::-webkit-input-placeholder {
            font-size: 16px;
            color: #ccc;
          }
        }
        > .btn {
          width: 440px;
          height: 52px;
          line-height: 52px;
          text-align: center;
          font-size: 18px;
          border: 1px solid;
          border-radius: 3px;
          cursor: pointer;
        }
        > .resgister {
          color: #ffffff;
          background-color: #f74848;
          border-color: #f74848;
          letter-spacing: 5px;
          margin-top: 70px;
          margin-bottom: 10px;
          height: 48px;
          line-height: 48px;
          &.disable {
            background-color: rgba(#f74848, 0.5);
            border-color: rgba(#f74848, 0.5);
          }
        }
        > .bottom {
          display: flex;
          // align-items: center;
          > p {
            line-height: 20px;
            width: 420px;
            .agree {
              color: #f74848;
              cursor: pointer;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}

.zuce {
  color: #0088fe;
  font-size: 20px !important;
  margin-bottom: 50px !important;
  justify-content: center;
}
.up {
  position: relative;
  z-index: 999;
}
.tiaoguo {
  text-align: right;
  user-select: none;
  color: #0088fe;
  position: relative;
  z-index: 99;
  & > span {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
#rentBg {
  .el-carousel__indicators {
    display: none;
  }
}
.app-el-form {
  font-size: calc(100vw / 750 * 20);
  // el-form组件代码
  .el-form-item {
    margin-bottom: 3rem;
  }
  .el-form-item__content {
    line-height: 1em;
    font-size: 1.4rem;
  }
  .el-input {
    font-size: 1.4rem;
  }
  .el-input__suffix {
    right: 1rem;
  }
  .el-input .el-input__clear {
    font-size: 1.4rem;
    line-height: 3rem;
  }
  .el-input__inner {
    line-height: 1;
    font-size: 1.4rem;
    height: 2.5em;
    padding-left: 1rem;
  }
  .el-form-item--small .el-form-item__error {
    padding-top: 0.5rem;
  }
  .el-form-item__error {
    font-size: 1.3rem;
  }
  .el-button {
    border-radius: 0.2em;
  }
  .el-button--danger {
    line-height: 1.75;
    font-size: 1.4rem;
  }
  .el-checkbox {
    font-size: 1.2rem;
    margin-right: 0.4em;
  }
  .el-checkbox__inner {
    width: 1.4rem;
    height: 1.4rem;
    &::after {
      width: 0.5rem;
      height: 0.7rem;
      border: 0.2rem solid #ffffff;
      border-left: 0;
      border-top: 0;
      left: 0.3rem;
    }
  }
}
</style>
